<!--
   /**
      * digitalCenter.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">数字看板</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Digital signage</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_b_60 margin_t_60">
                      <el-col :span="24">
                        <p class="textP" style="font-size: 18px;line-height: 40px">对海量丰富的数据内容进行清洗、计算、挖掘，得到具有专业价值的数据结果。通过人性化的图表设计呈现，为大众展示大数据成果，直观感受数据价值。</p>
                      </el-col>
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/digital01.png" style="width: 80%;"></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">优势</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">advantage</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_b_60 margin_t_60">
                      <el-col :span="24">
                        <p class="textP" style="font-size: 18px;line-height: 40px">多种数据源整合、数据同步更新、炫酷直观的可视化设计。</p>
                      </el-col>
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/digital02.png" style="width: 80%;"></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
